
import React, { useRef } from 'react'
import { Col, Divider, Row } from 'antd'
import { FaArrowDown } from 'react-icons/fa'
import { PiNumberCircleOne, PiNumberCircleTwo } from 'react-icons/pi'
import template1 from "../assets/template01.png"
import template2 from '../assets/template02.png'
import template3 from '../assets/template03.png'
import template4 from '../assets/template04.png'
import order1 from '../assets/home/order1.jfif'
import order2 from '../assets/home/order2.jfif'
import order3 from '../assets/home/order3.jfif'
import order4 from '../assets/home/order4.jfif'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import HowDoesWork from '../components/HowDoesWork'
import OrderCarousel from '../components/Carousels/OrderCarousel'

const Home = () => {
  const screensSectionRef = useRef<HTMLDivElement>(null)

  const handleScrollToScreens = () => {
    if (screensSectionRef.current) {
      screensSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleNavigateSchedule = () => {
    window.open('https://forms.gle/UsVn5pxwd1bGzLpG8');
  }
  return (
    <div className='home layoutPaddingInline'>
      <Navbar />
      <div className='hero_section'>
        <Row gutter={[16, 40]}>
          <Col lg={12} md={24} xs={24}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "300px"}}>
            <h2 className='heading'>Elevate the party.<br/> Light up the screens.</h2>
            <p className='subHeading'>
              Enabling paid on-screen shout outs at music and entertainment venues.
            </p>
            <button className='btnHome' type='button' onClick={handleNavigateSchedule}>
              Venue owners - Schedule a demo
            </button>
          </div>
          </Col>
          <Col lg={12} md={24} xs={24}>
            <img
              className='leftIMG'
              src={template1}
              alt='qr card'
              onError={() => {
                return 'image not found'
              }}
            />
          </Col>
        </Row>
      </div>
      <div className='downArrowBTN'>
        <div className='iconBg' onClick={handleScrollToScreens}>
          <FaArrowDown size={30} color='var(--fontHome)' />
        </div>
      </div>
      <Divider className='divider' />
      <div className='screens_section' ref={screensSectionRef}>
        <div className='topText'>
          <p className='leftHeading'>
            Unleash the power of something you already have .. your screens
          </p>
          <p className='rightHeading'>
            Customized plug and play solutions that lets your guests celebrate special occasions and
            moments with personalized messages on your screens.
          </p>
        </div>
        <div className='bottomScreens'>
          <div className='topScreensIMG'>
            <img
              className='screensIMG'
              src={template1}
              alt='screens 1'
              onError={() => {
                return 'image not found'
              }}
            />
            <img
              src={template2}
              className='screensIMG'
              alt='screens 2'
              onError={() => {
                return 'image not found'
              }}
            />
          </div>
          <div className='bottomScreensIMG'>
            <img
              src={template3}
              className='screensIMG'
              alt='screens 3 '
              onError={() => {
                return 'image not found'
              }}
            />
            <img
              src={template4}
              className='screensIMG'
              alt='screens 4'
              onError={() => {
                return 'image not found'
              }}
            />
          </div>
          <div className='mainHomeBTN'>
            <button className='btnHome' type='button' onClick={handleNavigateSchedule}>
              Venue owners - Schedule a demo
            </button>
          </div>
        </div>
      </div>
      <Divider className='divider' />
      <HowDoesWork />
      <div className='order_section'>
        <p>Easier than ordering a drink</p>
        <div className='order_images'>
          <img
            src={order1}
            alt='order 1'
            className='orderIMG'
            onError={() => {
              return 'image not found'
            }}
          
          />
          <img
            src={order2}
            alt='order 1'
            className='orderIMG'
            onError={() => {
              return 'image not found'
            }}
          />
          <img
            src={order3}
            alt='order 1'
            className='orderIMG'
            onError={() => {
              return 'image not found'
            }}
          />
          <img
            src={order4}
            alt='order 1'
            className='orderIMG'
            onError={() => {
              return 'image not found'
            }}
          />
        </div>
      </div>
      <div className='mobile_carousel'>
        <OrderCarousel />
      </div>
      <div className='cards'>
        <div className='card'>
          <PiNumberCircleOne size={40} />
          <h2 className='cardHeading'>Frictionless integration at your venue.</h2>
          <p className='cardSubHeading'>
            No need for complex installations, our platform integrates seamlessly with existing
            infrastructure at most venues.
          </p>
        </div>
        <div className='card'>
          <PiNumberCircleTwo size={40} />
          <h2 className='cardHeading'>
            Your venue, your rules. Venue and event owners dictate pricing.
          </h2>
          <p className='cardSubHeading'>
            We also offer deeper insights with our optional analysis and reporting tools to make
            data-driven decisions and maximize profits.
          </p>
        </div>
      </div>
      <div className='mainHomeBTN'>
        <button className='btnHome' type='button' onClick={handleNavigateSchedule}>
          Venue owners - Schedule a demo
        </button>
      </div>
      <Divider className='divider' />
      <Footer />
    </div>
  )
}

export default Home
