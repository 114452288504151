import React from "react";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import App from "./config/App";


const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F55139",
        },
      }}
    >
      <App />
    </ConfigProvider>
);

