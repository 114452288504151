import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface NavLinksProps {
  sider: boolean;
  onClose: () => void;
}
const NavLinks: React.FC<NavLinksProps> = ({ sider, onClose }) => {
  const [activeLink, setActiveLink] = useState<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    switch (pathname) {
      case "/":
        setActiveLink("home");
        break;
      case "/contact-us":
        setActiveLink("contact-us");
        break;

      default:
        setActiveLink("");
    }
  }, [pathname]);

  const handleNavigate = (to: any) => {
    if (sider) {
      onClose();
      navigate(to);
    } else {
      navigate(to);
    }
  };

  return (
    <ul>
      <li
        className={activeLink === "home" ? "active" : ""}
        onClick={() => {
          return handleNavigate("/home");
        }}
      >
        Home
      </li>
      <li
        className={activeLink === "contact-us" ? "active" : ""}
        onClick={() => {
          return window.open("https://forms.gle/UsVn5pxwd1bGzLpG8");
        }}
      >
        Contact Us
      </li>
    </ul>
  );
};

export default NavLinks;
