import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import PublicWrapper from "./PublicRoutes";
import Home from "../pages/Home";
import Terms from "../pages/Terms";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicWrapper />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
