// @ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import order1 from '../../assets/home/order1.jfif';
import order2 from '../../assets/home/order2.jfif';
import order3 from '../../assets/home/order3.jfif';
import order4 from '../../assets/home/order4.jfif';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    partialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 80,
  },
};

const OrderCarousel = () => {
  return (
    // @ts-ignore
    <Carousel
      ssr
      infinite
      swipeable
      draggable
      partialVisible
      keyBoardControl
      arrows={false}
      autoPlay={false}
      showDots={false}
      responsive={responsive}
      transitionDuration={500}
      customTransition="all .10"
    >
      {[order1, order2, order3, order4].map((order, index) => (
        <div key={index}>
          <img
            src={order}
            alt={`order ${index + 1}`}
            onError={() => {
              return 'image not found';
            }}
            style={{ width: '260px', height: '300px', borderRadius: '16px', margin: '1rem 0' }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default OrderCarousel;
