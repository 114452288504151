import { Divider } from "antd";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar";

const Terms = () => {
  return (
    <div className="home layoutPaddingInline">
      <Navbar />
      <div className="terms_container layoutPaddingInline">
        <div className="terms_header">
          <h1>
            Terms of Service
          </h1>
        </div>
        <div className="layoutPaddingInline terms_content_container">
          <h2>Discotext Media LLC Terms of Service</h2>
          <p>
            These Terms of Service reflect the way Discotext works and the laws
            that apply to our company. As a result, these Terms of Service help
            define Discotext’s relationship with you as you interact with our
            services.
          </p>
          <h2>Your expectations from us whenever you buy a Discotext</h2>
          <p>
            Discotext Media will provide you, the Customer, the ability to
            order, create and pay for a shoutout message to be displayed on the
            screens at a specific venue and at a specified date and time. For
            example: Send a customized birthday message to a loved one, wish
            your Spouse a happy anniversary and much more. We want our customers
            to have the most convenient, safe and memorable experiences while
            using our product.
          </p>
          <h2>To achieve this, Discotext will:</h2>
          <ol>
            <li>
              Review all Shout out requests to ensure they are safe and
              appropriate: To ensure the safety, privacy and wellbeing of our
              customers and business partners, Discotext reserves the right to
              reject any shoutout, photo, message or order, at any time.
              Specifically, Messages or photos intended to solicit business or
              advertise business will not be accepted, or that can be seen as
              obscene, pornographic, offensive, or that include foul language
              will not be accepted. In such cases, we will provide a written
              explanation of this decision via email to you, the customer.
              Discotext WILL NOT issue refunds for Discotexts rejected due to
              containing any of the content described herein. So please ensure
              that your Discotext order complies with this policy prior to your
              purchase.
            </li>
            <li>
              Edit Content of Shout out Messages where deemed necessary by the
              Discotext team: Shout out messages that are approved will be
              primarily displayed on the templates selected as part of the order
              as they are input by the Customer. However, Discotext might edit
              the content of any shoutout message, in order to correct for any
              minor typographical or grammatical errors or adjust messages to
              suit available Shout out templates. For example, if you
              accidentally spell birthday as “bithday”, we will correct that.
              Discotext will not issue refunds for shout out messages edited due
              to containing any typographical or grammatical errors or obscene,
              pornographic, or offensive content.
            </li>
            <li>
              Protect your Data: Through the course of providing our services,
              we will collect personal user data such as: Name, Contact
              Information, Payment Information, Device Information, Location
              information, and uploaded images. Discotext will ensure that all
              data you provide is only used and retained for the purpose of
              ensuring you have the most convenient, safest and memorable
              experiences. We will ensure that all your data that is no longer
              deemed useful to achieve this will be discarded in accordance with
              local regulations.
            </li>
          </ol>
          <h2>Our expectations from you whenever you buy a Discotext</h2>
          <p>
            Buying a Discotext is a big responsibility! For a specific time,
            your message will be displayed on the screens of a venue and viewed
            by many people. It is an exhilarating experience, however, to ensure
            that you, venue owners and other customers have a positive
            experience whenever you buy a Discotext, here are some expectations
            we have of you, our customer:
          </p>
          <ol>
            <li>
              Provide Shoutout Materials on time: Customers must provide all
              shoutout materials including photos, videos and messages to
              Discotext at least 1 hour before the desired time of publication
              through the Discotext website. This provides ample time for the
              discotext team to review your order.If shout out materials are not
              provided on time, Discotext cannot guarantee that the shoutout
              will be published by the date and time ordered by the customer. In
              this scenario, Discotext may choose to cancel the order and refund
              the customer, or publish the shoutout at a later time deemed
              appropriate by the team. We cannot currently accommodate any
              changes to the shoutout request by the Customer (including content
              of message, images) after payment has been received by us.
            </li>
            <li>
              Ensure content of Shout out requests are safe and appropriate:
              Customers must ensure that Messages or photos intended to solicit
              business or advertise business, or that can be seen as obscene,
              pornographic, offensive, or that include foul language are not
              submitted as part of the order. Orders containing this content
              will not be approved and refunds will not be re-issued.
            </li>
            <li>
              Ensure you have the authority to distribute shout out materials:
              By submitting an order, the Customer grants to Discotext a
              non-exclusive, worldwide, fully paid license to publish, use,
              perform, reproduce, display, transmit and distribute the shout out
              materials of any order. It is FULLY the Customer’s (and not
              Discotext’s) responsibility to ensure they have the right to own,
              or distribute these materials. For example, customers should only
              provide images you own or have purchased license to distribute to
              avoid any copyright infringement issues. By agreeing to order a
              Discotext, you are expressly stating that you have the right to
              whatever image, video or material that is part of the order.
            </li>
            <li>
              Ensure Payment is made on time: Customers must pay the total
              amount due for the Discotext purchased in advance of the date and
              time specified in the order. If a customer fails to make payments
              or an electronic payment is declined (e.g credit card), the order
              will be canceled and the Discotext will not be published. All
              payments for Discotexts are due are in U.S. dollars and are
              inclusive of any applicable digital sales taxes.
            </li>
          </ol>
          <h2>In case of problems or disagreements</h2>
          <ol>
            <li>
              Limitations of Liability. In no event shall Discotext be liable
              for any act or omission, or any event directly or indirectly
              resulting from any act or omission, of Agency or any third parties
              (if any). IN NO EVENT SHALL DISCOTEXT BE LIABLE UNDER THIS
              AGREEMENT FOR ANY CONSEQUENTIAL, SPECIAL, INDIRECT, EXEMPLARY,
              PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
              FOR LOSS OF DATA, LOST PROFITS, LOSS OF BUSINESS, OR PROCUREMENT
              OF SUBSTITUTE GOODS OR SERVICES, WHETHER BASED IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF DISCOTEXT HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY
              FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. DISCOTEXT'S
              AGGREGATE LIABILITY UNDER THIS AGREEMENT FOR ANY CLAIM IS LIMITED
              TO THE AMOUNT RECEIVED BY DISCOTEXT FROM CUSTOMER FOR THE ORDER
              GIVING RISE TO THE CLAIM. Without limiting the foregoing,
              Discotext shall have no liability for any failure or delay
              resulting from any disruptions at the event venue, governmental
              action, fire, flood, insurrection, earthquake, power failure,
              riot, terrorism, explosion, embargo, strikes whether legal or
              illegal, labor or material shortage, transportation interruption
              of any kind, work slowdown, Internet failures or communications
              lines failures or any other condition affecting production or
              delivery of the shoutouts in any manner beyond the control of
              Discotext. Customer acknowledges that Discotext has entered into
              this Agreement in reliance upon the limitations of liability set
              forth herein and that the same is an essential basis of the
              bargain between the parties.
            </li>
            <li>
              Customer's Representations Indemnification: Customer
              represents and warrants to Discotext and third parties (if any),
              (a) that Customer holds all necessary rights to permit the use of
              the shout out materials by Discotext for the purpose of this
              Agreement; and (b) that the use, reproduction, distribution,
              transmission or display of messages, photos and other type of
              media, selected by Customer, any data regarding users, will not
              (i) violate any criminal laws or any rights of any third parties;
              (ii) contain any material that is unlawful or otherwise
              objectionable, including without limitation any material that
              encourages conduct that would constitute a criminal offense, give
              rise to civil liability, or otherwise violate any applicable law.
              Customer agrees to defend Discotext and third parties (if any)
              from and against any third party claim (including damage awards,
              settlement amounts, and reasonable legal fees and expenses
              incurred by Customer in such defense), arising out of or related
              to (1) breach of any of the foregoing representations and
              warranties, (2) any third party claim arising from the content or
              of a shoutout message provided by Customer under this Agreement.
            </li>
            <li>
              Miscellaneous. This Agreement: (1) shall be governed by and
              construed in accordance with the laws of the State of California,
              without giving effect to principles of conflicts of law; and (2)
              will not be governed by the United Nations Convention of Contracts
              for the International Sale of Goods. This constitutes the entire
              agreement between the parties with respect to the subject matter
              hereof, and supersedes and prior oral or written agreements. All
              additions or modifications to this Agreement must be made in
              writing and must be signed by all Parties. Any dispute hereunder
              will be negotiated in good faith between the parties within thirty
              (30) days upon receiving written notice from one party to the
              other, provided however that this obligation does not eliminate
              any other remedies available to the parties. Any notices under
              this Agreement shall be sent to the addresses set forth in the
              Order (or in a separate writing) by facsimile or
              nationally-recognized express delivery service and shall be deemed
              given upon receipt. The waiver of any breach or default of this
              Agreement will not constitute a waiver of any subsequent breach or
              default, and will not act to amend or negate the rights of the
              waiving party. If any provision contained in this Agreement is
              determined to be invalid, illegal or unenforceable in any respect
              under any applicable law, then such provision will be severed and
              replaced with a new provision that most closely reflects the
              original intention of the parties, and the remaining provisions of
              this Agreement.
            </li>
            <li>
              Filing a Dispute: For any questions, concerns or issues with using
              discotext, please send an email to{" "}
              <a href="mailto:info@discotext.co">info@discotext.co</a>
            </li>
          </ol>
          <p>
            We may update these terms and service-specific additional terms (1)
            to reflect changes in our services or how we do business — for
            example, when we add new services, features, technologies, pricing,
            or benefits (or remove old ones), (2) for legal, regulatory, or
            security reasons, or (3) to prevent abuse or harm.
          </p>
          <p>
            If we materially change these terms or service-specific additional
            terms, we’ll provide you with reasonable advance notice and the
            opportunity to review the changes, except (1) when we launch a new
            service or feature, or (2) in urgent situations, such as preventing
            ongoing abuse or responding to legal requirements. If you don’t
            agree to the new terms, you should remove your content and stop
            using the services.
          </p>
        </div>
      </div>
      <Divider />
      <Footer />
    </div>
  );
};

export default Terms;
