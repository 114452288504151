import { useEffect, useState } from "react";
import "antd/dist/reset.css";
import "../styles/style.css";
import { light, dark } from "./theme";
// import { useAppSelector } from "../store/hook";
import { notification } from "antd";
import Routers from "./Routes";

const App = () => {
  const [online, setOnline] = useState(navigator?.onLine);

  // const theme = useAppSelector((state) => {
  //   return state.theme.theme;
  // });

  useEffect(() => {
    const updateBodyStyle = (themeObject: Record<string, string>) => {
      Object.entries(themeObject).forEach(([key, value]) => {
        document.body.style.setProperty(`--${key}`, value);
      });
    };

    // if (theme === "light") {
    //   updateBodyStyle(light);
    // } else {
      updateBodyStyle(dark);
    // }
  }, []);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(navigator?.onLine);
      if (!navigator?.onLine) {
        notification.error({
          message: "Check your internet connection",
        });
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

    // useEffect(() => {
  //   if (theme === 'light') {
  //     Object.keys(light).forEach((key)  => {
  //       document.body.style.setProperty(`--${key}`, light[key])
  //     })
  //   } else {
  //     Object.keys(dark).forEach((key) => {
  //       document.body.style.setProperty(`--${key}`, dark[key])
  //     })
  //   }
  // }, [theme])

  return (
    <>
      {online ? <Routers /> : null}
      {/* {!online && <ConnectionError />} */}
    </>
  );
};

export default App;

// import { useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import 'antd/dist/reset.css'

// import '../styles/style.css'
// import Routes from './Routes'
// import { light, dark } from './theme'

// const App = () => {
//   const theme = useSelector((state) => {return state.theme.theme} )

//   useEffect(() => {
//     if (theme === 'light') {
//       Object.keys(light).forEach((key) => {
//         document.body.style.setProperty(`--${key}`, light[key] )
//       })
//     } else {
//       Object.keys(dark).forEach((key) => {
//         document.body.style.setProperty(`--${key}`, dark[key])
//       })
//     }
//   }, [theme])

//   return <Routes />
// }

// export default App
