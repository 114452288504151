
import { Col, Row } from 'antd'
import React from 'react'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()

  return (
    <div className='Footer'>
      <Row gutter={[10, 10]}>
        <Col xl={12} lg={12} md={12} xs={12}>
          <div className='logo_copyright'>
            <img
              className='footerLogo'
              src={require('../../assets/pinkLogoWhiteText.png')}
              alt='Logo'
              onClick={() => {
                return navigate('/')
              }}
            />
            <p className='copyright'>&copy; {currentYear} Discotext</p>
          </div>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <div className='linkBoxes'>
            <div className='linkBoxTop'>
              <div className='linkBox'>
                <ul>
                  <li>Home</li>
                  <li>Partner with us</li>
                  <li onClick={() => navigate("/terms")}>Terms of Service</li>
                </ul>
              </div>
              <div className='linkBox'>
                <ul>
                  <li>Contact</li>
                  <li style={{visibility: 'hidden'}}>Empty list</li>
                  <li>info@discotext.com</li>
                </ul>
              </div>
            </div>
            <div className='linkBoxBottom'>
              <p>Follow us!</p>
              <div className='social_icons'>
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaInstagram />
                  </li>
                  <li>
                    <FaXTwitter />
                  </li>
                </ul>
              </div>
            </div>
            <p className='copyrightMobile'>&copy; {currentYear} Discotext</p>

          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Footer
