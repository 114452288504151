import React from "react";
import { Drawer } from "antd";
import "./style.css";

import { useNavigate } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";
import NavLinks from "../NavLinks";

const SideDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Drawer
      title=""
      placement="left"
      closable={false}
      onClose={onClose}
      open={open}
      key="left"
      className="drawer"
    >
      <div className="drawer_body">
        <div className="logo_box">
          <img
            src={require("../../../assets/pinkLogoWhiteText.png")}
            alt="Logo"
            onClick={() => {
              return navigate("/");
            }}
          />
          <IoCloseCircleSharp
            size={30}
            color="var(--primary)"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="menu_item_box">
          <NavLinks sider onClose={onClose} />
        </div>
      </div>
    </Drawer>
  );
};
export default SideDrawer;
