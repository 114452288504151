import React from 'react'

import HowDoesWork1 from './../assets/home/HowDoesWorkIMG1.png'
import HowDoesWork2 from '../assets/home/HowD2esWorkIMG1.png'
import HowDoesWork3 from '../assets/home/HowDoesWorkIMG3.png'
import HowDoesWork4 from '../assets/home/HowDoesWorkIMG4.png'

const HowDoesWork = () => {
  return (
    <div className='HowDoesWork'>
      <div className='HowDoesWork_container'>
        <h2>How Does It Work?</h2>
        <div className='HowDoesWork_cards'>
          <div className='card qrCodeCard'>
            <img src={HowDoesWork1} alt='HowDoesWorkIMG1' />
            <h2 className='heading'>Scan the QR Code</h2>
            <p className='text'>Guests scan the QR code displayed in your venue.</p>
          </div>
          <div className='card shoutoutCard'>
            <img src={HowDoesWork2} alt='HowDoesWorkIMG2' />
            <h2 className='heading'>Create Shoutout</h2>
            <p className='text'>Guests type their message.</p>
          </div>
          <div className='card paymentCard'>
            <img src={HowDoesWork3} alt='HowDoesWorkIMG3' />
            <h2 className='heading'>Make Payment</h2>
            <p className='text'>Secure payment options available.</p>
          </div>
          <div className='card goLiveCard'>
            <img src={HowDoesWork4} alt='HowDoesWorkIMG4' />
            <h2 className='heading'>Shoutout Goes Live</h2>
            <p className='text'>The shoutout is displayed on the big screen in real-time.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowDoesWork
