
import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";
import SideDrawer from "./SideDrawer";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="main_navbar">
      <div className="logo">
        <img
          className="logo"
          src={require("../../assets/pinkLogoWhiteText.png")}
          alt="Logo"
          onClick={() => {
            return navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="content">
        <div className="toggleBTN">
          <input type="checkbox" id="toggle" className="toggleCheckbox" />
          <label htmlFor="toggle" className="toggleContainer">
            <div
              onClick={() => {
                return navigate("/home");
              }}
            >
              Home
            </div>
            <div
              onClick={() => {
                return window.open("https://forms.gle/UsVn5pxwd1bGzLpG8");
              }}
            >
              Contact Us
            </div>
          </label>
        </div>
        <div className="menu_icon">
          <TiThMenu
            size={30}
            color="var(--fontHome)"
            style={{ cursor: "pointer" }}
            onClick={showDrawer}
          />
        </div>
      </div>
      <SideDrawer open={open} onClose={onClose} />
    </div>
  );
};

export default Navbar;
